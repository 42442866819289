<template>
    <div>
        <v-data-table
            :headers="Headers"
            :items="Items"
            :hide-default-footer="!ToLinked"
            :item-class="getClassColor"
            :options.sync="pagination"
            :server-items-length="TotalItems"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(TotalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.type`]="{ item }">
                <span>{{ getTypeOrder(item.type) }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span>{{ formatDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.total_gains`]="{ item }">
                <span>{{ formatMoney(item.total_gains) }}</span>
            </template>
            <template v-slot:[`item.value_to_pay`]="{ item }">
                <span>{{ formatMoney(item.value_to_pay) }}</span>
            </template>
            <template v-slot:[`item.rakeback_spin`]="{ item }">
                <span>{{ formatMoney(item.rakeback_spin) }}</span>
            </template>
            <template v-slot:[`item.rakeback`]="{ item }">
                <span>{{ formatMoney(item.rakeback) }}</span>
            </template>
            <template v-slot:[`item.total_value_to_pay`]="{ item }">
                <span>{{ formatMoney(item.total_value_to_pay) }}</span>
            </template>
            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{
                    formatTextVicnulations(
                        item.linked_to,
                        item.order_id,
                        item.transaction_id,
                    )
                }}</span>
            </template>
            <template v-if="ToLinked" v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            color="black"
                            class="mr-2"
                            @click="link(item)"
                            >mdi-link-variant</v-icon
                        >
                    </template>
                    <span>Vincular</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatDate, formatMoney } from '@util/format_util'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodConciliationClosureDatatable',
    props: {
        Headers: Array,
        Items: Array,
        TotalItems: Number || 1,
        ToLinked: Boolean,
    },
    computed: {
        ...mapState('period_conciliation_closures', ['getClassColor']),
        ...mapState('period_conciliation_dialog_vinculation', [
            'callGetRecords',
        ]),
        ...mapFields('period_conciliation_dialog_vinculation', ['pagination']),
    },

    methods: {
       ...mapActions('period_conciliation_dialog_vinculation', [
            'linkRegistry',
            'getRecordsRelated',
        ]),
        link(item) {
            console.log('LINK', item)
            item.tab_linked = 5
            this.linkRegistry(item)
        },
        formatDate(date) {
            return formatDate(date)
        },
        formatMoney(value) {
            return formatMoney(value)
        },
        getTypeOrder(type) {
            let types = [
                'Retirada de Fichas',
                'Envio de Fichas',
                'Retirada de Fichas Maleta',
                'Envio de Fichas Maleta',
            ]
            return types[type]
        },
        formatTextVicnulations(linked_to, order_id, transaction_id) {
            if (order_id || transaction_id) {
                return `${linked_to} de 2`
            }
            return `${linked_to} de 1`
        },
    },
     watch: {
        pagination: {
            handler() {
                if (this.ToLinked && this.callGetRecords) {
                    console.log("GET RECORDS RELATED", this.callGetRecords)
                    this.getRecordsRelated()
                }
            },
        },
    },
}
</script>